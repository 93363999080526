<template>
  <div class="">
    <screen ref="screen" height="500" :options="{ zoomScaleSensitivity: 0 }">
      <edge
        v-for="edge in graph.edges"
        :data="edge"
        :nodes="graph.nodes"
        :key="edge.id"
      >
      </edge>

      <node v-for="(node, idx) in graph.nodes" :data="node" :key="node.id">
        <div class="">
          <slot name="node" :data="node.data" :idx="idx"></slot>
        </div>
        <!-- html can be placed here, defaults to <div>{{node.id}}</div> -->
      </node>
    </screen>
  </div>
</template>

<script>
import { Screen, Node, Edge, graph } from 'vnodes'
export default {
  components: {
    Screen,
    Node,
    Edge
  },
  data: () => {
    return {
      graph: new graph()
    }
  },
  props: {
    nodes: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    generateTreePositions(
      nodes,
      parentName = null,
      x = 300,
      y = 100,
      dx = 310,
      dy = 120
    ) {
      const positions = {}

      const parent = nodes.find(node => node.value === parentName) || nodes[0]
      if (parent) {
        const children = nodes.filter(node => node.parent === parent.value)
        const totalWidth = children.reduce((acc, child) => {
          const childSubtree = nodes.filter(node => node.parent === child.value)
          const childWidth =
            childSubtree.length > 0 ? childSubtree.length * dx : dx
          return acc + childWidth
        }, 0)

        const startX = x - totalWidth / 2 + 200

        positions[parent.value] = [x, y]
        parent.x = x
        parent.y = y

        let currentX = startX

        for (const child of children) {
          const childY = y + dy
          positions[child.value] = [currentX, childY]
          child.x = currentX
          child.y = childY

          const childPositions = this.generateTreePositions(
            nodes,
            child.value,
            currentX,
            childY,
            dx,
            dy
          )
          Object.assign(positions, childPositions)

          const childSubtree = nodes.filter(node => node.parent === child.value)
          const childWidth =
            childSubtree.length > 0 ? childSubtree.length * dx : dx
          currentX += childWidth
        }
      }

      return positions
    }
  },
  mounted() {
    const screenWidth = this.$refs.screen
      ? this.$refs.screen.$el.width.baseVal.value
      : 500
    const nodes = this.nodes
    const positions = this.generateTreePositions(
      nodes,
      null,
      screenWidth / 2 - 150
    )
    nodes.forEach(node => {
      this.graph.createNode({
        id: node.value,
        x: positions[node.value][0],
        y: positions[node.value][1],
        data: node
      })
      if (node.parent !== 'null') {
        const parent = nodes.find(n => n.value === node.parent)
        if (parent) {
          this.graph.createEdge(parent.value, node.value)
        }
      }
    })

    // this.graph.graphNodes()
  }
}
</script>

<style></style>
