import http from '../axios'

const getCountries = async () => {
  const res = await http.get(`${process.env.VUE_APP_NEW_API}country`)
  return res.data
}
const getCallerIds = async () => {
  const res = await http.get(`${process.env.VUE_APP_NEW_API}jera/dids`)
  return res.data
}

export default {
  getCountries,
  getCallerIds
}
