<template>
  <div class="sound-wave">
    <div v-show="loading" class="loading">
      <half-circle-spinner
        slot="loading"
        :animation-duration="1500"
        :size="25"
        color="#74b9ff"
        class="mx-auto"
      />
    </div>

    <div v-show="!loading" class="wave" :id="uid"></div>
  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.js'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import { mapGetters } from 'vuex'
import { regionMargin } from '../constants/soundwave'

export default {
  data() {
    return {
      uid: 'wave',
      waveSurfer: null,
      waveSurferRegions: null,
      loading: false,
      position: 0,
      regions: []
    }
  },
  props: {
    soundFile: {
      type: String,
      required: true
    },
    initialMarkers: {
      type: Array,
      required: false,
      default: () => []
    },
    markers: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    ...mapGetters['palette']
  },
  mounted() {
    this.waveSurferInit()
  },
  watch: {
    markers(val) {
      this.waveSurferRegions.clearRegions()
      this.regions = []
      this.drawMarkers()
    },
    initialMarkers() {
      if (!this.markers.length) {
        this.waveSurferRegions.clearRegions()
        this.regions = []

        this.drawInitialMarkers()
      }
    },
    soundFile(sound) {
      this.loading = true
      this.waveSurferRegions.clearRegions()
      this.regions = []

      this.waveSurfer.load(sound)
    }
  },
  methods: {
    createMarkerElement(content) {
      const htmlString = `<div>${content}</div>`
      let div = document.createElement('div')
      div.innerHTML = htmlString.trim()
      return div.firstChild
    },
    drawMarker(options) {
      if (options.location >= this.waveSurfer.duration - regionMargin) {
        console.log(this.regions)
        this.toast("you can't add a variable at the end of the sound track", {
          type: 'error'
        })
        return
      }

      const region = this.waveSurferRegions.addRegion(options)
      this.regions = [...this.regions, { ...region, ...options }]
      const regionEl = region.element
      const deleteButton = document.createElement('button')

      deleteButton.addEventListener('click', e => {
        e.stopPropagation()
        this.$emit('regionRemoved', { region, options })
        region.remove()
      })

      deleteButton.innerHTML = 'X'
      regionEl.prepend(deleteButton)
    },
    drawInitialMarkers() {
      this.initialMarkers.forEach(regionData => {
        this.drawMarker({
          ...regionData,
          start: regionData.location || 0,
          content: this.createMarkerElement(regionData.variable_name),
          color: regionData.color
        })
      })
    },
    drawMarkers() {
      this.markers.forEach((regionData, key) => {
        this.drawMarker({
          ...regionData,
          start: regionData.location || 0,
          content: this.createMarkerElement(regionData.variable_name),
          color: regionData.color
        })
      })
    },
    waveSurferInit() {
      const palette = this.$store.getters.palette
      this.uid = 'wave' + new Date().getTime()
      this.loading = true

      const waveSurfer = WaveSurfer.create({
        container: `.sound-wave .wave`,
        cursorColor: palette.primary,
        cursorWidth: 2,
        barWidth: 2,
        barRadius: 2,
        barGap: 2,
        waveColor: '#999',
        progressColor: palette.primary,
        fetchParams: {
          headers: {
            'ngrok-skip-browser-warning': true,
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      })

      waveSurfer.load(this.soundFile)
      const waveSurferRegions = waveSurfer.registerPlugin(
        RegionsPlugin.create()
      )

      // events
      waveSurfer.on('ready', () => {
        this.loading = false
        this.$emit('ready', { waveSurfer, waveSurferRegions })
        this.drawInitialMarkers()
      })
      waveSurfer.on('timeupdate', position => {
        this.position = position
        this.$emit('input', position)
      })
      waveSurfer.on('play', () => {
        this.status = 'playing'
        this.$emit('statusChange', 'playing')
      })
      waveSurfer.on('pause', () => {
        this.status = 'paused'
        this.$emit('statusChange', 'paused')
      })

      waveSurferRegions.on('region-updated', region => {
        let updatedRegion = region
        if (region.start >= this.waveSurfer.duration - regionMargin) {
          const prevRegion = this.regions.find(r => region.id === r.id)
          this.$emit('regionUpdated', prevRegion)

          this.toast("you can't add a variable at the end of the sound track", {
            type: 'error'
          })
          return
        }

        this.regions = this.regions.map(r => {
          if (region.id === r.id) {
            updatedRegion = { ...r, ...region, location: region.start || 0 }
            return updatedRegion
          }

          return r
        })
        this.$emit('regionUpdated', updatedRegion)
      })

      // save refs
      this.waveSurfer = waveSurfer
      this.waveSurferRegions = waveSurferRegions
    }
  },
  components: {
    HalfCircleSpinner
  }
}
</script>

<style lang="scss" scoped>
.sound-wave {
  cursor: cell;
  // border: 3px solid #008dcf;
  padding: 8px 16px;
  border-radius: 8px;
  background: #008dcf1f;
  background: linear-gradient(180deg, #008dcf00 0%, #008dcf2f 100%);
}
</style>
